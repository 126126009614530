<template>
  <div class="page-wrap">
    <div class="session-form-hold">
      <base-card>
        <v-card-text class="text-center">
          <v-avatar
            size="60"
            class="mb-4"
            tile
          >
            <img
              src="/assets/logo/ktgLogo.png"
              alt=""
            >
          </v-avatar>
          <p class="text--disabled font-weight-medium mb-10">
            New password will be sent to your email address
          </p>
          <v-text-field label="Email" v-model="email" />
          <v-btn
            class="mb-4"
            block
            color="primary"
            dark
            @click="forgotPasswordClick"
          >
            Submit
          </v-btn>
          <div class="d-flex justify-around">
            <v-btn
              text
              small
              color="primary"
              to="/app/sessions/sign-in"
            >
              Sign In
            </v-btn>
            <v-btn
              text
              small
              color="primary"
              to="/app/sessions/sign-up"
            >
              Create New Account
            </v-btn>
            <!-- <small>New sign ups are closed for 2025</small> -->
          </div>
        </v-card-text>
      </base-card>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
  export default {
    name: 'Forgot',
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Forgot',
    },
    methods: {
      ...mapActions(["forgotPassword"]),
      forgotPasswordClick(){
        this.forgotPassword(this.email);
      }
    },
    data(){
      return {
        email: ''
      }
    }
  }
</script>
<style lang="scss" scoped>
.page-wrap {
    background-color: #242939 !important;
    display: flex;
    align-items: center;
    padding: 40px 1rem;
    height: 100%;
    min-height: 100vh;
}
.session-form-hold {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}
</style>
